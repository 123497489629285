
import { onMounted, provide, ref } from 'vue'

import { NAME_REG } from './constant'

export const useKidsFormData = ({ language, isModified }) => {
  const nickname = ref('')
  const month = ref('')
  const year = ref('')
  const sex = ref('2')

  const handleDateSelect = (data) => {
    if (data) {
      month.value = data.month
      year.value = data.year
    }
  }

  const initFormData = (data) => {
    if (isModified) {
      nickname.value = data.nickname || ''
    } else {
      nickname.value = language.SHEIN_KEY_PWA_32905 || ''
    }
    
    const [y, m] = data.birthday?.split('-') || []
    month.value = m || ''
    year.value = y || ''
    sex.value = String(data.sex || '') || '2'
  }

  const getFormData = () => {
    return {
      nickname: nickname.value,
      month: month.value,
      year: year.value,
      sex: sex.value,
    }
  }

  provide('KIDS_FORM_DATA', {
    nickname,
    month,
    year,
    sex,
    handleDateSelect,
  })

  return {
    initFormData,
    getFormData,
  }
}

export const useKidsValidate = ({ getFormData, language, isBirthdayOver }) => {

  const isNameValid = ref(true)
  const nameErrorMsg = ref('')
  const isBirthdayValid = ref(true)
  const birthdayErrorMsg = ref('')

  if (isBirthdayOver.value) {
    isBirthdayValid.value = false
    birthdayErrorMsg.value = language.SHEIN_KEY_PWA_32977 || ''
  }

  const checkName = () => {
    const nickname = getFormData().nickname
    if (!nickname) {
      isNameValid.value = false
      nameErrorMsg.value = language.SHEIN_KEY_PWA_32971 || ''
      return
    }
    if (!NAME_REG.test(nickname)) {
      isNameValid.value = false
      nameErrorMsg.value = language.SHEIN_KEY_PWA_32971 || ''
      return
    }
    isNameValid.value = true
    nameErrorMsg.value = ''
  }

  const checkBirthday = () => {
    const month = getFormData().month
    const year = getFormData().year
    if (!month || !year) {
      isBirthdayValid.value = false
      birthdayErrorMsg.value = language.SHEIN_KEY_PWA_32970 || ''
      return
    }
    const currentYear = new Date().getFullYear()
    if (currentYear - Number(year) > 16) {
      isBirthdayValid.value = false
      birthdayErrorMsg.value = language.SHEIN_KEY_PWA_32977 || ''
      return
    }
    if (currentYear - Number(year) === 16) {
      const currentMonth = new Date().getMonth() + 1
      if (month <= currentMonth) {
        isBirthdayValid.value = false
        birthdayErrorMsg.value = language.SHEIN_KEY_PWA_32977 || ''
        return
      }
    }
    isBirthdayValid.value = true
    birthdayErrorMsg.value = ''
  }

  const checkForm = () => {
    checkName()
    checkBirthday()
    return isNameValid.value && isBirthdayValid.value
  }

  provide('KIDS_FORM_VALIDATE', {
    isNameValid,
    nameErrorMsg,
    checkName,
    isBirthdayValid,
    birthdayErrorMsg,
    checkBirthday,
  })

  return {
    checkForm,
  }
}

export const useAndroidKeyboard = () => {

  const showFooter = ref(true)

  onMounted(() => {
    const isAndroid = typeof window !== 'undefined' && navigator.userAgent?.toLowerCase().indexOf('android') > -1
    const winH = window.innerHeight
    window.addEventListener('resize', () => {
      if (!isAndroid) {
        return
      }
      const newInnerHeight = window.innerHeight
      if (winH > newInnerHeight) {
        showFooter.value = false
      } else {
        showFooter.value = true
      }
    })
  })

  return {
    showFooter,
  }
}
