<script>
import { TAG_ITEM_TYPE } from 'public/src/pages/components/FilterBar/utils/const.js'
import TagAttr from './TagAttr/index.vue'
// import TagCloudTag from'./TagCloudTag/index.vue'
import TagText from './TagCloudTag/TagText.vue'
import TagCccText from './TagCloudTag/TagCccText.vue'
import TagCccImage from './TagCloudTag/TagCccImage.vue'
import TagQs from './TagCloudTag/TagQs.vue'
import TagCategory from'./TagCategory/index.vue'
import TagDaily from'./TagDaily/index.vue'
import TagPicNavCate from'./TagPicNavCate/index.vue'
import TagPrices from'./TagPrices/index.vue'
import TagTsp from'./TagTsp/index.vue'
import TagKids from'./TagKids/index.vue'
import TagBrand from'./TagBrand/index.vue'
import TagCustom from './TagCustom/index.vue'

export default {
  name: 'TagItem',
  functional: true,
  render(h, { props, listeners, data }) {
    let tagComponent = TagAttr
    switch (props.item.tagItemType) {
      case TAG_ITEM_TYPE.ATTR:
        tagComponent = TagAttr
        break
      case TAG_ITEM_TYPE.CLOUD_TAG:
        if (props.item?.isNewQs) {
          tagComponent = TagQs
        } else if (!props.item?.cccConfig) {
          tagComponent = TagText
        }else if (props.item?.cccConfig?.displayType == 1){
          tagComponent = TagCccImage
        } else {
          tagComponent = TagCccText
        }
        break
      case TAG_ITEM_TYPE.CATEGORY:
        tagComponent = TagCategory
        break
      case TAG_ITEM_TYPE.DAILY:
        tagComponent = TagDaily
        break
      case TAG_ITEM_TYPE.PIC_NAV_CATE:
        tagComponent = TagPicNavCate
        break
      case TAG_ITEM_TYPE.PRICES:
        tagComponent = TagPrices
        break
      case TAG_ITEM_TYPE.TSP:
        tagComponent = TagTsp
        break
      case TAG_ITEM_TYPE.KIDS:
        tagComponent = TagKids
        break
      case TAG_ITEM_TYPE.BRAND:
        tagComponent = TagBrand
        break
      case TAG_ITEM_TYPE.CUSTOM:
        tagComponent = TagCustom
        break
      default:
        tagComponent = TagAttr
    }

    return h(tagComponent, {
      props,
      key: data.key,
      on: {
        toStickyByClick: listeners.toStickyByClick,
        beforeOpen: listeners.beforeOpen,
        change: listeners.change,
        saveCurrAttr: listeners.saveCurrAttr,
        visibleChange: listeners.visibleChange,
        click: listeners?.click
      }
    })
  }
}

</script>
