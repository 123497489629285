<script lang="jsx">
import DropdownCommon from '../DropdownCommon.vue'
import DropdownSelect from 'public/src/pages/components/filter_new/components/DropdownSelect'

export default {
  name: 'DropdownCategoryNew',
  functional: true,
  render(_, { props, listeners }) {
    const { 
      sum = 0, 
      icon = '', 
      label = '', 
      active = false, selected = false, loading = false, closeBottomLeft = false, item = {}, styles = {}, language = {}, urlSelectedId = '', 
      openDelay = 0,
    } = props

    const renderContent = () => {
      return (  
        <DropdownSelect
          border-top
          panel-show={active}
          data={item.data}
          sum={sum}
          url-selected-id={urlSelectedId}
          language={language}
          close-bottom-left={closeBottomLeft}
          loading={loading}
        />)
    }
    
    return (
      <DropdownCommon
        label={label}
        icon={icon}
        loading={loading}
        selected={selected}
        open-delay={openDelay}
        styles={styles}
        on={listeners}
      >
        { renderContent() }
      </DropdownCommon>
    )
  }
}
</script>
