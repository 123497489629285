import { cloudTagsFilterInner, handleCloudTagsByCccConfig } from 'public/src/pages/components/FilterBar/utils/index.js'
import { getCateList } from 'public/src/pages/components/FilterBar/utils/category.js'
import { getActiveTspMap, formatTspList } from 'public/src/pages/components/FilterBar/utils/tsp.js'
import { getActiveAttrList, filterAttrsByNodeType, formatAttrList, getIsCustomAttr } from 'public/src/pages/components/FilterBar/utils/attr.js'
import { filterBrandByNodeType, formatBrandList, getActiveBrandList } from 'public/src/pages/components/FilterBar/utils/brand.js'
import { filterCustomByNodeType, formatCustomList, getActiveCustomList } from 'public/src/pages/components/FilterBar/utils/custom.js'

// cloud tags
function getFilterNumByCloudTags (cloudTags, curValue) { 
  // 查看标签是否存在
  const hasTag = cloudTags.some(tag => curValue == tag.tag_id || tag.value == curValue)

  return curValue && hasTag ? 1 : 0
}
export function setMenuConfigByCloudTags ({ config, filterBar, queryParams, language, listAbtResult, queryParamsKey = 'tag_ids' }) {
  const cloudTags = filterBar?.filterData?.cloudTags || []
  const list = handleCloudTagsByCccConfig({
    filterType: 'filterExposed',
    cloudTags: cloudTags.filter((item) => cloudTagsFilterInner(item, listAbtResult)),
    productTypeSortConfig: filterBar?.filterConfig?.productTypeSortConfig || []
  })
  if (!list.length) return 

  const curValue = queryParams[queryParamsKey]
  config.menuBarConfig.unshift({
    key: 'cloudTags',
    text: language.SHEIN_KEY_PWA_18695, // 商品类型
    active: true,
    count: true,
    filterNum: getFilterNumByCloudTags(list, curValue)
  })

  config.menuItemsConfig.unshift({
    type: 'single',
    dataType: 'shopType', // 商品类型
    collapse: 2,
    data: {
      data: list,
      curValue
    }
  })
}

// cate
function getFilterNumByCategory (cateData) {
  let flag = false
  const { data, curValue } = cateData.data
  const loop = (obj) => {
    if (obj.value === curValue) {
      flag = true
      return true
    }
    if (obj.children && obj.children.length) {
      return obj.children.find(child => loop(child))
    }
    return false
  }

  data.find(obj => loop(obj, flag))
  return flag ? 1 : 0
}

export function setMenuConfigByCategory ({ catInfo, config, listAbtResult, filterBar, language, queryParams, queryParamsKey }) {
  const cateData = getCateList({
    catInfo,
    filterBar,
    listAbtResult,
    queryParams,
    language,
    queryParamsKey
  })[0]

  if (!cateData) return 
  config.menuBarConfig.push({
    key: 'cate',
    text: language.SHEIN_KEY_PWA_14980,
    link: true,
    active: true,
    filterNum: getFilterNumByCategory(cateData),
  })

  config.menuItemsConfig.push({
    ...cateData,
    type: 'single',
    dataType: 'cate',
    collapse: 2,
    step: catInfo?.pageName === 'page_search' ? 20 : 0 // 搜索页逐步展示
  })
}

// tsp
function getFilterNumByTsp (tsp, selectedMulti) {
  if (!selectedMulti.length) return 0
  if (tsp.selectedNum || tsp.selectedNum === 0) return tsp.selectedNum
    
  let filterNum = 0
  tsp?.data?.forEach(item => {
    if (selectedMulti.includes(item.id)) filterNum += 1
  })

  return filterNum
}
export function setMenuConfigByTsp ({ config, filterBar, queryParams }) {
  const filterTsps = filterBar?.filterData?.filterTsps || []
  if (!filterTsps.length) return

  const activeTspMap = getActiveTspMap(queryParams)
  const tspList = formatTspList(filterTsps)
  const activeIndexTsp = [0, 1] // 默认展开前两项

  tspList.forEach((tsp, index) => {
    if (activeTspMap[tsp.id]) {
      activeIndexTsp.push(index)
    }

    const selectedMulti = activeTspMap[tsp.id] || []
    config.menuBarConfig.push({
      key: `tsp_${tsp.id}`,
      text: tsp.name,
      count: true,  // 选中后的数量
      active: activeIndexTsp.includes(index),
      filterNum: getFilterNumByTsp(tsp, selectedMulti)
    })

    config.menuItemsConfig.push({
      type: 'tsp',
      collapse: index < 2 ? 2 : 0, // 超过多少行收齐
      data: tsp,
      selectedMulti,
    })
  })
}

// price
function getFilterNumByFilters (filterPrices) {
  const { startMinPrice = '', startMaxPrice = '' } = filterPrices

  return (String(startMinPrice) && String(startMaxPrice)) ? 1 : 0
}
export function setMenuConfigByPrices ({ config, filterBar, queryParams, language, listAbtResult, queryParamsMinPriceKey = 'min_price',  queryParamsMaxPriceKey = 'max_price' }) {
  let filterPrices = filterBar?.filterData?.filterPrices || {}
  const { min_price, max_price } = filterPrices
  const startMinPrice = queryParams[queryParamsMinPriceKey] ?? ''
  const startMaxPrice = queryParams[queryParamsMaxPriceKey] ?? ''
  if (!min_price && !max_price || min_price == max_price) return

  if (String(startMinPrice) && String(startMaxPrice)) {
    filterPrices = {
      ...filterPrices,
      startMinPrice,
      startMaxPrice
    }
  }
  const isInput  = listAbtResult?.ListPriceFilter?.p?.ListPriceFilter === 'Input'
  const barConfig = {
    key: 'price',
    type: 'price',
    text: `${language.SHEIN_KEY_PWA_16527}(${filterBar?.filterConfig?.currency || ''})`,
    active: true,
    normal: true,
    filterNum: getFilterNumByFilters(filterPrices)
  }
  const itemConfig = {
    type: 'price',
    data: filterPrices,
    isInput,
    language: {
      to: language.SHEIN_KEY_PWA_27244,
      min: language.SHEIN_KEY_PWA_27242,
      max: language.SHEIN_KEY_PWA_27243,
    }
  }

  // F/G分支下移动到product type下
  if (isInput) {
    config.menuBarConfig.unshift(barConfig)
    config.menuItemsConfig.unshift(itemConfig)
  } else {
    config.menuBarConfig.push(barConfig)
    config.menuItemsConfig.push(itemConfig)
  }
}

// attr
function getFilterNumByAttr (attr, selectedMulti) {
  if (!selectedMulti.length) return 0
  if (attr.selectedNum || attr.selectedNum === 0) return attr.selectedNum

  let selectedNum = 0
  attr.data.forEach(item => {
    if (item.mix_value?.split('-')?.some(mix_value => selectedMulti.includes(mix_value))) {
      selectedNum += 1
    }
  })
  
  return selectedNum
  
}
export function setMenuConfigByAttr ({ config, filterBar, queryParams, queryParamsKey }) { 
  const { isCccxFilterConfig } = filterBar?.filterConfig || {}
  let filterAttrs = filterBar?.filterData?.filterAttrs || []
  filterAttrs = isCccxFilterConfig ? filterAttrsByNodeType(filterAttrs) : filterAttrs
  if (!filterAttrs.length) return

  const isExistTsp = config.menuItemsConfig.find(item => item.type === 'tsp')
  const activeIndexList = isExistTsp ? [] : [0, 1]  // 有tsp标签时不默认展开，否则默认展开前两项
  const attrList = formatAttrList(filterAttrs, filterBar)
  const activeAttrList = getActiveAttrList(attrList, queryParams, filterBar, queryParamsKey)
  attrList.forEach((attr, index) => {
    const activeAttrs = attr.data .reduce((acc, cur) => {
      if (isCccxFilterConfig) {
        activeAttrList?.includes(cur.nodeId) && acc.push(cur.nodeId)
      }else if (activeAttrList?.includes(cur.mix_value)) {
        if (cur.nodeType == 3) return acc.concat(cur.mix_value?.split('-') || [])
        
        acc.push(cur.mix_value)
      }

      return acc
    }, [])

    if (activeAttrs?.length) {
      activeIndexList.push(index)
    }

    const active = activeIndexList.includes(index)
    const selectedMulti = attr.id == 27
      ? (activeAttrs || [])
        .map(_ => _?.split('-') || [])
        .reduce((acc, cur) => {
          return acc.concat(cur)
        }, [])
      : activeAttrs || []
    
    config.menuBarConfig.push({
      key: `attr_${attr.id}`,
      text: attr.name,
      count: true,
      active,
      filterNum: getFilterNumByAttr(attr, selectedMulti)
    })
    
    config.menuItemsConfig.push({
      type: attr.show_group ? 'img' : 'multi',
      collapse: index < 2 ? 2 : 0,
      data: attr,
      selectedMulti,
    })
  })

}

// custom
function getFilterNumByCustom (attr, selectedMulti) {
  if (!selectedMulti.length) return 0

  if (attr.selectedNum || attr.selectedNum === 0) return attr.selectedNum

  let selectedNum = 0
  const isLevel3 = attr?.data?.some(item => item?.data?.length > 0) // 区分自定义筛选2/3级结构数据
  if (isLevel3) {
    attr.data.forEach(item => {
      item.data.forEach(subItem => {
        if (subItem.mix_value?.split('-')?.some(mix_value => selectedMulti.includes(mix_value))) {
          selectedNum += 1
        }
      })
    })
  } else {
    attr.data.forEach(item => {
      if (item.mix_value?.split('-')?.some(mix_value => selectedMulti.includes(mix_value))) {
        selectedNum += 1
      }
    })
  }
  
  return selectedNum
  
}
export function setMenuConfigByCustom ({ config, filterBar, queryParams }) { 
  const filterCustom = filterCustomByNodeType(filterBar?.filterData?.filterAttrs || [])

  if (!filterCustom.length) return
  const customList = formatCustomList(filterCustom, filterBar)
  const activeCustomList = getActiveCustomList(customList, queryParams)
  customList.forEach((custom) => {
    config.menuBarConfig.push({
      key: `attr_${custom.id}`,
      text: custom.name,
      count: true,
      active: true,
      isCustomAttr: true,
      filterNum: getFilterNumByCustom(custom, activeCustomList)
    })
    
    config.menuItemsConfig.push({
      type: 'multi',
      data: custom,
      selectedMulti: activeCustomList,
    })
  })

}

// brand
function getFilterNumByBrand (attr, selectedMulti) {
  if (!selectedMulti.length) return 0

  if (attr.selectedNum || attr.selectedNum === 0) return attr.selectedNum

  let selectedNum = 0
  if (getIsCustomAttr(attr)) {
    attr.data.forEach(item => {
      item.data.forEach(subItem => {
        if (subItem.mix_value?.split('-')?.some(mix_value => selectedMulti.includes(mix_value))) {
          selectedNum += 1
        }
      })
    })
  } else {
    attr.data.forEach(item => {
      if (item.mix_value?.split('-')?.some(mix_value => selectedMulti.includes(mix_value))) {
        selectedNum += 1
      }
    })
  }
  

  return selectedNum
  
}
export function setMenuConfigByBrand ({ config, filterBar, queryParams }) { 
  const filterBrand = filterBrandByNodeType(filterBar?.filterData?.filterAttrs)

  if (!filterBrand.length) return
  const brandList = formatBrandList(filterBrand)
  const activeBrandList = getActiveBrandList(queryParams)
  brandList.forEach((attr) => {
    const activeBrand = attr.data .reduce((acc, cur) => {
      if (activeBrandList?.includes(cur.mix_value)) {
        acc.push(cur.mix_value)
      }
      return acc
    }, [])
    
    config.menuBarConfig.push({
      key: `attr_${attr.id}`,
      text: attr.name,
      count: true,
      active: false,
      filterNum: getFilterNumByBrand(attr, activeBrand)
    })
    
    config.menuItemsConfig.push({
      type: attr.show_group ? 'img' : 'multi',
      data: attr,
      selectedMulti: activeBrand,
    })
  })

}

// 排序
export function sortMenuConfigByCccxFilterConfig ({ config, filterBar }) {
  const { cccxFilterConfig = [] } = filterBar?.filterConfig || {}
  if (!cccxFilterConfig.length) return

  const newMenuBarConfig = []
  const newMenuItemsConfig = []
  const { menuBarConfig = [], menuItemsConfig = [] } = config
  // product type 处理
  const productTypeIndex = menuItemsConfig.findIndex(item => item?.dataType === 'shopType')
  if (productTypeIndex > -1) {
    newMenuBarConfig.push(menuBarConfig[productTypeIndex])
    newMenuItemsConfig.push(menuItemsConfig[productTypeIndex])
  }

  // cate 处理
  const cateCccxConfig = cccxFilterConfig.find(node => node.nodeType == 6) // cccxConfig中分类配置
  const cateIndex = menuItemsConfig.findIndex(item => item?.dataType === 'cate')
  if (cateIndex > -1 && !cateCccxConfig) { 
    newMenuBarConfig.push(menuBarConfig[cateIndex])
    newMenuItemsConfig.push(menuItemsConfig[cateIndex])
  }
  // cccxConfig 处理
  cccxFilterConfig.forEach(node => {
    let index = node.nodeType == 6
      ? menuItemsConfig.findIndex(item => item?.dataType == 'cate')
      : menuItemsConfig.findIndex(item => item?.data?.nodeType == node.nodeType && item.data.id == node.nodeId)

    if (index < 0) return

    newMenuBarConfig.push(menuBarConfig[index])
    newMenuItemsConfig.push(menuItemsConfig[index])
  })

  // price处理
  const priceIndex = menuItemsConfig.findIndex(item => item?.type === 'price')
  if (priceIndex > -1) {
    newMenuBarConfig.push(menuBarConfig[priceIndex])
    newMenuItemsConfig.push(menuItemsConfig[priceIndex])
  }

  config.menuBarConfig = newMenuBarConfig
  config.menuItemsConfig = newMenuItemsConfig
}
export function sortMenuConfigByPrice ({ config, filterBar }) { 
  const { cccxFilterConfig = [] } = filterBar?.filterConfig || {}
  if (!cccxFilterConfig.length) return

  const { menuBarConfig = [], menuItemsConfig = [] } = config

  const priceIndex = menuItemsConfig.findIndex(item => item?.type === 'price')
  if (priceIndex < 0 || !menuItemsConfig[priceIndex]?.isInput) return

  
  const productTypeIndex = menuItemsConfig.findIndex(item => item?.dataType === 'shopType')
  const spliceIndex = productTypeIndex > -1 ? 1 : 0
  const priceBar = menuBarConfig.splice(priceIndex, 1)[0]
  const priceItem = menuItemsConfig.splice(priceIndex, 1)[0]
  menuBarConfig.splice(spliceIndex, 0, priceBar)
  menuItemsConfig.splice(spliceIndex, 0, priceItem)
}

export function sortMenuConfig ({ config, filterBar }) {
  sortMenuConfigByCccxFilterConfig({ config, filterBar })
  sortMenuConfigByPrice({ config, filterBar })
}

// 设置active
export function setMenuActive ({ config, filterBar }) {
  const { cccxFilterConfig = [] } = filterBar?.filterConfig || {}
  if (!cccxFilterConfig.length) return

  let index = 0
  let max = 2 // 默认展开前2个,price在前面时候，展开前3个
  const { menuBarConfig } = config
  const leng = menuBarConfig.length
  // 全部设置为隐藏[price除外]
  menuBarConfig.forEach((item) => {
    if (item.type === 'price' || item.isCustomAttr  || item.filterNum) return

    item.active = false
  })

  while (index < leng && index < max) {
    menuBarConfig[index].type === 'price' && (max++)
    menuBarConfig[index].active = true
    index++
  }

}
