<template>
  <div
    class="dropdown-common"
    :class="{'dropdown-common__active': visible}"
  >
    <DropdownLabel
      :icon="icon"
      :label="label"
      :styles="styles"
      :visible="visible"
      :selected="selected"
      :is-show-icon="isShowIcon"
      :class-name="labelClassName"
      @click="onClick"
    />

    <DropdownPanel
      v-if="isLoadSlide"
      ref="refDropdownPanel"
      :visible.sync="visible"
      :loading="loading"
      @close="closeFromMask"
    >
      <slot></slot>
    </DropdownPanel>
  </div>
</template>

<script>
export default {
  name: 'DropdownCommon',
}
</script>

<script setup>
import { onMounted, getCurrentInstance, watch } from 'vue'

import DropdownLabel from './DropdownLabel.vue'
import DropdownPanel from './DropdownPanel.vue'

// hooks
import { useVisible, useIsLoadSlide } from './hooks/index.js'
import { setDisabledScroll } from 'public/src/pages/components/FilterBar/utils/disabledScroll.js'

// const DropdownLabel = defineAsyncComponent(() => import('./DropdownLabel.vue'))
// const DropdownPanel = defineAsyncComponent(() => import('./DropdownPanel.vue'))


// props
const props = defineProps({
  icon: { type: String, default: '' },
  label: { type: String, default: '' },
  styles: { type: Object, default: () => ({}) },
  loading: { type: Boolean, default: false },
  selected: { type: Boolean, default: false },
  labelClassName: { type: String, default: '' },
  openDelay: { type: Number, default: 0 }, // 延时打开【自动吸顶需要等待图文大小图切换】
  closeOnClickModal: { type: Boolean, default: true },
  disabled: { type: Boolean, default: false }, // 禁止展开【NavBarSort组件使用】
  isShowIcon: { type: Boolean, default: true }, // 是否展开展开/收起 icon
})

// emits
const emits = defineEmits(['beforeOpen', 'beforeClose', 'loadSlide'])
const { visible, close, toggle } = useVisible()
const isLoadSlide = useIsLoadSlide(visible)

watch(isLoadSlide, () => emits('loadSlide'))
watch(visible, () => emits('visibleChange', visible.value))

const onClick = async () => {
  setDisabledScroll()
  emits('click', !visible.value)
  if (props.disabled) return
  !visible.value ? emits('beforeOpen') : emits('beforeClose')
  if (props.openDelay && !visible.value) {
    const timer = setTimeout(() => {
      clearTimeout(timer)
      toggle()
    }, props.openDelay)
  } else {
    toggle()
  }
}

const closeFromMask = () => { 
  if (props.loading) return

  close()
}

onMounted(() => {
  getCurrentInstance()?.proxy?.$on('clickDone', close)
})

defineExpose({ close })

</script>


