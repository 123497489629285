<script lang="jsx">
import DropdownCommon from '../DropdownCommon.vue'
import SingleSelect from 'public/src/pages/components/filter_new/components/SingleSelect'

export default {
  name: 'DropdownCategoryOld',
  functional: true,
  render(_, { props, listeners }) {
    const { sum = 0, 
      icon = '', 
      label = '',
      visible = false, 
      selected = false, loading = false, closeBottomLeft = false, styles = {}, item = {}, 
      language = {}, 
      openDelay = 0,
    } = props

    const renderContent = () => {
      return (
        <SingleSelect
          border-top
          type="tree"
          data={item.data}
          visible={visible}
          sum={sum}
          url-selected-id={item.selectedId}
          language={language}
          close-bottom-left={closeBottomLeft}
          loading={loading}
        />
      )
    }

    return (
      <DropdownCommon
        label={label}
        icon={icon}
        loading={loading}
        selected={selected}
        open-delay={openDelay}
        styles={styles}
        on={listeners}
      >
        { renderContent() }
      </DropdownCommon>
    )
  }
}
</script>
